<template>
  <div>
    <div v-html="description" class="mt-7 prose max-w-none" :class="[$theme.baseTextColor]"></div>
    <template v-if="link_video">
      <iframe
          :src="youtubeEmbedUrl(link_video)"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="w-full h-48 mt-4"
      ></iframe>
    </template>
  </div>
</template>

<script>
export default {
  props: [
    'description',
    'link_video',
  ],

  methods: {
    youtubeEmbedUrl(url) {
      const videoIdMatch = url.match(
          /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&]+)/
      );
      const videoId = videoIdMatch ? videoIdMatch[1] : '';
      return `https://www.youtube.com/embed/${videoId}`;
    },
  },
}
</script>