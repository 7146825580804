<template>
  <div class="relative z-10">
    <img :src="iconPath" class="w-7 border-2 rounded-full bg-white" :alt="symbol" :class="[$theme.borderColorCoin]" />
  </div>
</template>

<script>
export default {
  props: [
    'symbol'
  ],

  computed: {
    iconPath() {
      let coin = this.symbol.replace("USDT", "").toLowerCase();
      coin = coin.replace("usdc", "").toLowerCase();
      try {
        return require(`../../../node_modules/cryptocurrency-icons/svg/color/${coin}.svg`)
      } catch (e) {
        return '/img/' + coin + '.png'
      }
    }
  },
}
</script>
