<template>
  <div>
    <div class="bg-yellow-100 p-4">
      <div class="flex">
        <div class="shrink-0">
          <InformationCircleIcon class="h-5 w-5 text-yellow-600" aria-hidden="true" />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <h4 class="font-medium text-yellow-700">{{ $t('emergency_alert_title') }}</h4>
          <p v-if="list_errors.tariff_selected" class="text-sm text-yellow-700"><span aria-hidden="true"> &rarr;</span> {{ $t('select_tariff_alert') }}</p>
          <p v-if="list_errors.tariff_expired" class="text-sm text-yellow-700"><span aria-hidden="true"> &rarr;</span> {{ $t('tariff_expired_alert') }}</p>
          <p v-if="list_errors.signals_disabled" class="text-sm text-yellow-700"><span aria-hidden="true"> &rarr;</span> {{ $t('enable_signals_alert') }}</p>
          <p v-if="list_errors.exchange_disconnected" class="text-sm text-yellow-700"><span aria-hidden="true"> &rarr;</span> {{ $t('exchange_disconnected_alert') }}</p>
          <p v-if="list_errors.balance_unavailable" class="text-sm text-yellow-700"><span aria-hidden="true"> &rarr;</span> {{ $t('balance_unavailable_alert') }}</p>
          <p class="mt-3 text-sm md:ml-6 md:mt-0">
            <router-link to="/setting" class="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600">
              {{ $t('settings') }}
              <span aria-hidden="true"> &rarr;</span>
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {InformationCircleIcon} from "@heroicons/vue/20/solid";

export default {
  props: ['list_errors'],
  components: {
    InformationCircleIcon
  },
}
</script>