<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-2xl font-bold mb-5">
      {{ $t('how_can_help_you') }}
    </h3>

    <div class="mb-4">
      <input
          v-model="searchQuery"
          type="text"
          :placeholder="`${$t('search')}...`"
          class="block w-full rounded-xl border-0 py-2 ring-1 ring-inset focus:ring-2 focus:ring-inset"
          :class="[
              $theme.cardBg,
              $theme.inputRingColor,
              $theme.baseTextColor,
              $theme.inputRingFocusColor,
              $theme.inputPlaceTextColor,
          ]"
      />
    </div>

    <template v-if="loading">
      <div class="rounded-xl" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>

    <template v-else>
      <template v-if="showItem">
        <div class="rounded-xl mb-4 px-3 py-3" :class="[$theme.cardBg]">
          <div class="border-b pb-2 mb-2 flex items-center" :class="[$theme.cardBorderColor]">
            <button @click="onBack" type="button" class="rounded-lg p-1 mr-2" :class="[$theme.iconBtnBg]">
              <ChevronLeftIcon class="w-5" />
            </button>
            {{ showCategoryTitle }}
          </div>
          <div class="text-sm">
            <div class="font-semibold" v-html="highlightText(showItem.question, searchQuery)"></div>
            <RenderHtml :content="highlightText(showItem.answer, searchQuery)" />
          </div>
        </div>
      </template>

      <template v-else>
        <div v-for="category in filteredCategories" :key="category.id">
          <div v-if="category.items.length > 0" class="rounded-xl mb-4 px-3 py-3" :class="[$theme.cardBg]">
            <div class="border-b pb-2 mb-2" :class="[$theme.cardBorderColor]" v-html="highlightText(category.title, searchQuery)"></div>
            <div v-for="(item, itemIdx) in category.items" :key="item.id" :class="[itemIdx === (category.items.length - 1) ? '' : 'mb-2']">
              <a @click="onShowItem(category.title, item)" href="javascript:void(0)" class="text-sm flex w-full underline decoration-dotted" :class="[$theme.navActiveTextColor2]">
                <DocumentTextIcon class="w-4 h-4 mr-2 flex-shrink-0 relative top-1" />
                <span v-html="highlightText(item.question, searchQuery)"></span>
              </a>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'
import { DocumentTextIcon, ChevronLeftIcon } from "@heroicons/vue/24/outline";
import RenderHtml from "@/components/elem/RenderHtml.vue";
import { addBackButton, hideBackButton } from "@/helpers/telegramHelper";

export default {
  components: {
    LoadingBlock,
    DocumentTextIcon,
    ChevronLeftIcon,
    RenderHtml
  },

  data() {
    return {
      faq: null,
      loading: true,
      showItem: null,
      showCategoryTitle: '',
      searchQuery: '',
    }
  },

  computed: {
    filteredCategories() {
      if (!this.faq) return [];
      const query = this.searchQuery.toLowerCase();
      return this.faq.filter(category =>
          category.title.toLowerCase().includes(query) ||
          category.items.some(item => item.question.toLowerCase().includes(query))
      );
    }
  },

  methods: {
    onLoadFaq() {
      this.$api.get('/faq').then(result => {
        console.log('result', result.data)
        this.faq = result.data;
        this.loading = false;
      })
    },

    onBack() {
      this.showCategoryTitle = '';
      this.showItem = null;
    },

    onShowItem(categoryTitle, item) {
      this.showCategoryTitle = categoryTitle;
      this.showItem = item;
      console.log(this.showItem);
    },

    highlightText(text, query) {
      if (!text || typeof text !== 'string') return text;
      if (!query) return text;
      const regex = new RegExp(`(${query})`, 'gi');
      return text.replace(regex, '<mark>$1</mark>');
    }
  },

  mounted() {
    this.onLoadFaq();
    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>
