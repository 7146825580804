<template>
  <div class="pt-4 px-4">
    <h1 class="text-3xl font-bold mb-5">{{ $t('stats') }} {{ exchangeTitle }} ID: {{ stats && stats.exchange.id }}</h1>
    <template v-if="loading">
      <div class="flex flex-col rounded-lg p-3 pb-0 mb-3" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <div class="block">
        <nav class="flex space-x-4 mb-5" aria-label="Tabs">
          <a
              v-for="tab in tabs"
              :key="tab.key"
              href="javascript:void(0)"
              :class="[
              tab.key === activePeriod ? `${$theme.activeTabBg} ${$theme.activeTabTextColor}` : $theme.tabTextColor,
              'rounded-md px-3 py-2 text-sm font-medium'
            ]"
              @click="onChangePeriod(tab.key)"
          >{{ tab.name }}</a>
        </nav>
      </div>

      <div class="rounded-xl overflow-hidden" :class="[$theme.cardBg]">
        <template v-if="loading || chartLoading">
          <ChartPlaceholder />
        </template>
        <template v-else>
          <TheBarChart :chart_data="stats.chart_data" :period="activePeriod" />
        </template>
        <dl class="mx-auto border-t grid gap-px grid-cols-2" :class="[$theme.cardBorderColor]">
          <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
            <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">P&L (USDT)</dt>
            <dd :class="[stats.pnl_percentage < 0 ? $theme.dangerPnlColor : $theme.successPnlColor, 'text-xs font-medium']">
              {{ roundAmount(stats.pnl_percentage) }}%
            </dd>
            <dd
                class="w-full flex-none text-xl font-medium tracking-tight"
                :class="[stats.pnl < 0 ? $theme.dangerPnlColor : $theme.successPnlColor]"
            >
              {{ roundAmount(stats.pnl)}}
            </dd>
          </div>
          <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
            <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">
              {{ $t('positions') }}
            </dt>
            <dd class="w-full flex-none text-xl font-medium tracking-tight" :class="[$theme.baseTextColor]">
              {{ stats.orders_count || 0 }}
            </dd>
          </div>
        </dl>
      </div>

      <!-- Новый блок для процентного соотношения успешных и неуспешных ордеров -->
      <div class="rounded-xl overflow-hidden mt-5 p-4" :class="[$theme.cardBg]">
        <h2 class="text-lg font-bold mb-3">{{ $t('orders_stats') }}</h2>
        <dl class="grid grid-cols-2 gap-4">
          <div class="flex flex-wrap items-baseline justify-between">
            <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">{{ $t('success_orders') }}</dt>
            <dd
                class="w-full text-xl font-medium tracking-tight"
                :class="[stats.success_percentage > 0 ? $theme.successPnlColor : $theme.baseTextColor]"
            >
              {{ stats.success_percentage || 0 }}%
            </dd>
          </div>
          <div class="flex flex-wrap items-baseline justify-between">
            <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">{{ $t('unsuccess_orders') }}</dt>
            <dd
                class="w-full text-xl font-medium tracking-tight"
                :class="[stats.unsuccess_percentage > 0 ? $theme.dangerPnlColor : $theme.baseTextColor]"
            >
              {{ stats.unsuccess_percentage || 0 }}%
            </dd>
          </div>
        </dl>
      </div>
    </template>
  </div>
</template>

<script>
import TheBarChart from '@/components/elem/TheBarChart.vue'
import LoadingBlock from "@/components/LoadingBlock.vue";
import ChartPlaceholder from "@/components/placeholders/ChartPlaceholder.vue";
import {roundAmount} from '@/helpers/numberHelper'
import {capitalizeFirstLetter} from "@/helpers/stringHelper";
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";

export default {
  components: {
    LoadingBlock,
    TheBarChart,
    ChartPlaceholder,
  },

  computed: {
    exchangeTitle() {
      if (!this.stats) {
        return '';
      }

      return capitalizeFirstLetter(this.stats.exchange.name);
    },
  },

  methods: {
    onChangePeriod(period) {
      this.activePeriod = period;

      this.onLoadStats(this.$route.params.id);
    },

    onLoadStats(id) {
      this.$api.get(`/exchanges/${id}/stats?period=${this.activePeriod}`).then((result) => {
        console.log(result.data)
        this.stats = result.data;
      }).finally(() => {
        this.loading = false;
      })
    }
  },

  data() {
    return {
      loading: true,
      stats: null,
      activePeriod: '7days',
      tabs: [
        {
          name: this.$t('7days'),
          key: '7days',
        },
        {
          name: this.$t('14days'),
          key: '14days',
        },
        {
          name: this.$t('30days'),
          key: 'month',
        },
      ],
      roundAmount
    }
  },

  mounted() {
    addBackButton(this.$router);

    this.onLoadStats(this.$route.params.id)
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>
<script setup>
</script>