<template>
  <div class="pt-4 px-4">
    <h1 class="text-3xl font-bold mb-5">{{ $t('stats') }}</h1>

    <template v-if="loading">
      <div class="flex flex-col rounded-lg p-3 pb-0 mb-3" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <template v-if="exchanges.length">
        <div>
          <div v-for="exchange in exchanges" :key="exchange.id" class="flex flex-col rounded-xl p-3 pb-0 mb-3" :class="[$theme.cardBg]">
            <div class="border-b pb-3 flex items-center justify-between" :class="[$theme.baseTextColor, $theme.cardBorderColor]">
              <div class="flex items-center">
                <img :src="`/img/${exchange.exchange_slug}.png`" class="w-8 rounded-full mr-2">
                {{ exchange.exchange_slug.toUpperCase() }}
              </div>
              <div>
                <router-link :to="`/exchange/${exchange.id}/stats`" :class="[$theme.navActiveTextColor2, 'flex items-center']">
                  {{ $t('stats') }}
                  <ChevronRightIcon class="w-5 ml-1" />
                </router-link>
              </div>
            </div>
            <div v-if="exchange.assets.length">
              <table class="min-w-full divide-y" :class="[$theme.cardDivideColor]">
                <thead :class="[$theme.cardLabelTextColor]">
                  <th scope="col" class="py-2 pr-3 font-normal text-left text-sm sm:pl-0">
                    {{ $t('coin') }}
                  </th>
                  <th scope="col" class="px-1 py-2 font-normal text-left text-sm">
                    {{ $t('available_for_trade') }}
                  </th>
                  <th scope="col" class="font-normal text-right text-sm py-2">
                    {{ $t('balance') }}
                  </th>
                </thead>
                <tbody class="divide-y" :class="[$theme.cardDivideColor]">
                <tr v-for="(asset, index) in exchange.assets" :key="index">
                  <td class="whitespace-nowrap py-4 pr-3 text-sm font-medium" :class="[$theme.baseTextColor]">
                    <div class="flex items-center">
                      <img :src="`/img/assets/${asset.symbol}.png`" class="w-6 mr-2" />
                      {{ asset.symbol }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-1 py-4 text-sm">
                    {{ roundAmount(asset.available_balance) }}
                  </td>
                  <td class="relative whitespace-nowrap py-4 pl-1 text-right text-sm sm:pr-0">
                    {{ roundAmount(asset.balance) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center pt-10 flex flex-col items-center w-full">
          <SquaresPlusIcon class="w-10" />
          <h3 class="mt-2 text-sm font-semibold" :class="[$theme.baseTextColor]">
            {{ $t('no_connection_exchange') }}
          </h3>
          <p class="mt-1 text-sm" :class="[$theme.cardLabelTextColor]">
            {{ $t('you_need_setup_connection') }}
          </p>
          <div class="mt-6 w-full">
            <router-link
                to="/create-exchange"
                class="flex w-full items-center justify-center rounded-lg px-3 py-3 font-semibold text-white shadow-sm"
                :class="[$theme.inverseBaseBg]"
            >
              {{ $t('connect_exchange') }}
            </router-link>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'
import {
  SquaresPlusIcon,
  ChevronRightIcon,
} from '@heroicons/vue/24/outline'
import {roundAmount} from '@/helpers/numberHelper'

export default {
  components: {
    SquaresPlusIcon,
    ChevronRightIcon,
    LoadingBlock,
  },

  methods: {
    loadExchanges() {
      this.$api.get('/exchanges/assets').then((result) => {
        console.log(result.data)

        this.exchanges = result.data
        this.loading = false
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
  },

  data() {
    return {
      loading: true,
      exchanges: [],
      error: null,
      intervalId: null,
      roundAmount
    }
  },

  mounted() {
    this.loadExchanges()
  }
}
</script>
