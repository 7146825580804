<template>
  <div class="min-h-screen" :class="[$theme.bgGuideTour]">
    <div v-for="(tour, index) in tours" :key="index" :class="[
      index === active ? '' : 'hidden',
      'flex flex-col justify-between h-full'
    ]">
      <div class="px-6 pt-10 pb-28">
        <div class="flex">
          <div v-for="(t, i) in tours" :key="i" @click="onToSlide(i)" :class="[
            i === active ? $theme.inverseBaseBg : $theme.placeBg,
            'h-2 w-full rounded-xl mr-2'
          ]"></div>
        </div>

        <div class="text-4xl font-bold mt-10">{{ tour.title }}</div>
        <template v-if="tour.image">
          <div>
            <img :src="tour.image" class="w-full h-48 object-cover mt-7 rounded-xl">
          </div>
        </template>
        <TourDescription :description="tour.description" :link_video="tour.link_video" />
      </div>
      <div class="px-4 pb-6 fixed bottom-0 left-0 w-full">
        <template v-if="index < (tours.length - 1)">
          <button
              @click="onNextSlide(index)"
              type="button"
              class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white"
              :class="[
                isFormProcessing ? 'opacity-50 pointer-events-none' : '',
                $theme.inverseBaseBg,
              ]"
          >
            {{ $t('next') }}
          </button>
        </template>
        <template v-else>
          <button
              @click="onFinishTour"
              type="button"
              class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white"
              :class="[
                isFormProcessing ? 'opacity-50 pointer-events-none' : '',
                $theme.inverseBaseBg,
              ]"
          >
            <template v-if="isFormProcessing">
              <ButtonSpinner />
            </template>
            {{ $t('go_to_setup') }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonSpinner from "@/components/ButtonSpinner.vue";
import TourDescription from "@/components/elem/TourDescription.vue";

export default {
  components: {
    ButtonSpinner,
    TourDescription,
  },
  data() {
    return {
      tours: [],
      active: 0,
      isFormProcessing: false,
    }
  },

  mounted() {
    this.updateRegStatus();
    this.loadTours();
  },

  methods: {
    onNextSlide(index) {
      this.active = index + 1;
    },

    onToSlide(index) {
      this.active = index;
    },

    onFinishTour() {
      this.isFormProcessing = true;

      this.$api.post('/user/apply-guide').then(result => {
        console.log('result', result);
        this.$emit('action-success', 1);
        this.isFormProcessing = false;
      });
    },

    updateRegStatus() {
      this.$api.post('/user/update-reg', {
        status: 'VIEW_GUIDE'
      }).then(() => {
      });
    },

    loadTours() {
      this.$api.get('/guide-tour').then((result) => {
        console.log(result.data.data)
        this.tours = result.data.data;
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
  }
}
</script>