<template>
  <div class="flex items-center flex-wrap">
    <div
      v-for="(ip, i) in list_ips.split(',')"
      :class="[$theme.navActiveBg]"
      class="px-2 py-1 rounded-full text-xs mb-1 mr-1"
      :key="i"
    >{{ ip }}</div>
    <button class="copy-button px-2 py-1 rounded-full text-xs mb-1 mr-1" :class="[$theme.activeTabBg, $theme.navActiveTextColor2]" @click="copyToClipboard">
      {{ $t('copy') }}
    </button>
    <input type="hidden" ref="ipAddressInput" :value="list_ips">
  </div>
</template>

<script>
export default {
  props: [
    'list_ips'
  ],

  methods: {
    copyToClipboard() {
      const copyText = this.$refs.ipAddressInput;
      copyText.select();
      document.execCommand('copy');
      alert(this.$t('copied_ip_address') + ' ' + this.list_ips);
    }
  }
}
</script>